import { Formik } from 'formik';
import gql from 'graphql-tag';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import Button from '../../components/button';
import NavBar from '../../components/navbar';
import Page from '../../components/page';
import Row from '../../components/row';
import Select from '../../components/select';
import TextInput from '../../components/textInput';
import Title from '../../components/title';
import { history } from '../../store';
import { colours } from '../../styles/theme';
import { LoadingContext } from '../../utils/loadingContext';
import { UserContext } from '../../utils/userContext';
import { Container, Content, Half, Quarter } from './style';
import { Role } from '../../shared/Role';

const DELETE_USER = gql`
  mutation deleteTypeUser($userID: Int!, $type: String) {
    deleteTypeUser(userID: $userID, type: $type)
  }
`;

const SUBMIT_USER = gql`
  mutation createTypeUser($user: updateUserInput) {
    createTypeUser(user: $user)
  }
`;

const EDIT_USER = gql`
  mutation updateTypeUser($user: updateUserInput) {
    updateTypeUser(user: $user)
  }
`;

const GET_CLUBS = gql`
  {
    clubs: getClubs {
      id
      name
    }
  }
`;

const GET_BUSINESS = gql`
  {
    business: getBusinesses {
      id
      name
    }
  }
`;

const GET_EMPLOYEE_CLUBS = gql`
  query getEmployeeClubs($employeeID: Int) {
    getEmployeeClubs(employeeID: $employeeID) {
      id
      name
    }
  }
`;

const types = [{ name: 'Admins' }, { name: 'Coupon Employees' }, { name: 'Employees' }];
const roles = [{ name: Role.Admin }, { name: Role.Staff }];

const Users = () => {
  const userCtx = useContext(UserContext);
  const loadingCtx = useContext(LoadingContext);
  const user = (userCtx!.user as any) || {};
  const isEditing = user.id;

  const [insertUser] = useMutation(SUBMIT_USER);
  const [deleteUser] = useMutation(DELETE_USER);
  const [updateUser] = useMutation(EDIT_USER);
  const getClubs = useQuery(GET_CLUBS);
  const getBusiness = useQuery(GET_BUSINESS);

  const [type, setType] = useState({ name: user.type });
  const [role, setRole] = useState({ name: user.role });
  const getEmployeeClubs = useQuery(GET_EMPLOYEE_CLUBS, {
    skip: !isEditing || type.name !== 'Employees',
    variables: { employeeID: user.id },
    fetchPolicy: 'no-cache',
  });
  const [clubs, setClubs] = useState<any[]>();
  const [businessID, setBusinessID] = useState(user.businessID);

  const clubsToShow = getClubs.data?.clubs ?? [];
  const businessToShow = getBusiness.data?.business ?? [];
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setClubs(getEmployeeClubs.data ? getEmployeeClubs.data.getEmployeeClubs : []);
    if (user.type === 'Employees') {
      setBusinessID(getEmployeeClubs.data ? getEmployeeClubs.data.getEmployeeClubs[0].id : undefined);
    } else {
      setBusinessID(getBusiness.data && getBusiness.data.business ? getBusiness.data.business.find((e: any) => e.id === user.businessID) : '');
    }
  }, [getEmployeeClubs.data, getBusiness.data]);

  const isValid = (vals: any) => {
    if (!vals.email) return alert('The email is missing');
    if (!vals.name) return alert('The name is missing');
    if (!vals.password) return alert('The password is missing');
    if (!role || !role.name) return alert('The role is missing');

    if (type.name === 'Employees' && (!clubs || clubs.length === 0)) return alert('The clubs are missing');
    return true;
  };

  const onCreate = async (vals: any) => {
    if (submitting) return;
    setSubmitting(true);

    if (!type.name) {
      setSubmitting(false);
      return alert('Choose a type');
    }
    if (isValid(vals) && vals.password) {
      try {
        loadingCtx!.setLoading(true);
        await insertUser(createUser(vals));
        navigate();
      } catch (e) {
        doCatch(e);
      }
    } else {
      setSubmitting(false);
    }
  };

  const onUpdate = async (vals: any) => {
    if (submitting) return;
    setSubmitting(true);

    if (!type.name) {
      setSubmitting(false);
      return alert('Choose a type');
    }

    if (isValid(vals)) {
      try {
        loadingCtx!.setLoading(true);
        await updateUser(createUser(vals));
        navigate();
      } catch (e) {
        doCatch(e);
      }
    } else {
      setSubmitting(false);
    }
  };

  const onDelete = async () => {
    if (submitting) return;
    setSubmitting(true);
    if (window.confirm('Are you sure? The user will be permanently deleted')) {
      try {
        loadingCtx!.setLoading(true);
        await deleteUser({
          variables: {
            type: type.name,
            userID: user.id,
          },
        });
        navigate();
      } catch (e) {
        doCatch(e);
      }
    } else {
      setSubmitting(false);
    }
  };

  const doCatch = (e: any) => {
    console.error(JSON.stringify(e));
    setSubmitting(false);
    if (e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message) alert(e.graphQLErrors[0].message);
    else alert('Something went wrong');
    loadingCtx!.setLoading(false);
  };

  const navigate = () => {
    loadingCtx!.setLoading(false);
    setSubmitting(false);
    history.replace('/admin');
  };

  const createUser = (vals: any) => {
    const toUpdate: any = {
      variables: { user: { role: role.name, type: type.name, email: vals.email, name: vals.name, password: vals.password, id: user.id } },
    };
    if (user.id) {
      toUpdate.variables.user.id = user.id;
    }

    if (type.name === 'Employees' && clubs) {
      toUpdate.variables.user.clubs = clubs.map((a: any) => ({ name: a.name, id: a.id }));
    }
    if (type.name === 'Coupon Employees' && businessID.id) {
      toUpdate.variables.user.businessID = businessID.id;
    }
    return toUpdate;
  };

  if (getEmployeeClubs.loading || getClubs.loading || getBusiness.loading || (isEditing && type?.name !== 'Admins' && !businessID)) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <NavBar />
      <Container>
        <Title>{isEditing ? 'Edit ' : 'Create '} user</Title>

        <Formik
          initialValues={{
            email: user.email || '',
            name: user.name || '',
            role: user.role || '',
            type: user.type || '',
            password: '',
          }}
          onSubmit={() => null}>
          {({ values, handleChange }) => (
            <Content>
              <Row>{!isEditing && <Select data={types} label="Type" keyProp="name" onSelect={(i: any) => setType(i)} selectedValue={type} />}</Row>
              <Row>
                <Half>
                  <TextInput autocomplete="off" dark label="EMAIL" name="email" value={values.email} onChange={handleChange} />
                </Half>
                <Half>
                  <TextInput autocomplete="off" dark label="NAME" name="name" value={values.name} onChange={handleChange} />
                </Half>
              </Row>

              <Row>
                <Half>
                  <TextInput
                    autocomplete="off"
                    type="password"
                    dark
                    label="PASSWORD"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                </Half>
                <Half>
                  {type.name && type.name !== 'Admins' && (
                    <Select data={roles} label="ROLE" keyProp="name" onSelect={(i: any) => setRole(i)} selectedValue={role} />
                  )}
                  {type.name === 'Employees' && clubs && clubsToShow && (
                    <Select data={clubsToShow} label="Clubs" keyProp="name" multiple onSelect={(i: any) => setClubs(i)} selectedValue={clubs} />
                  )}
                  {type.name === 'Coupon Employees' && businessToShow && (
                    <Select
                      data={businessToShow}
                      label="Business"
                      keyProp="name"
                      onSelect={(i: any) => setBusinessID(i)}
                      selectedValue={businessID}
                    />
                  )}
                </Half>
              </Row>

              <Row>
                {isEditing && (
                  <Quarter style={{ marginRight: isMobile ? 25 : 8 }}>
                    <Button text="DELETE" onClick={() => onDelete()} colour={colours.red} style={{ height: 40, width: '100%' }} />
                  </Quarter>
                )}
                <Quarter>
                  {isEditing && (
                    <Button text="UPDATE" onClick={() => onUpdate(values)} colour={colours.green} style={{ width: '100%', height: 40 }} />
                  )}
                  {!isEditing && (
                    <Button text="CREATE" onClick={() => onCreate(values)} colour={colours.green} style={{ width: '100%', height: 40 }} />
                  )}
                </Quarter>
              </Row>
            </Content>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default Users;
